import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../index.scss';
import './guides.scss';
import '../../generic-page.scss';
import { Card } from 'react-bootstrap';
import { Reverse1999Character } from '../../../modules/reverse/common/components/rev-character';

const RevShouldAvgust: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page reverse r99-guide'}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Isle Cryptid</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/reverse/categories/category_cryptid.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Isle Cryptid</h1>
          <h2>Everything you need to know about the Isle Cryptid Raid!</h2>
          <p>
            Last updated: <strong>19/08/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <SectionHeader title="Introduction" />
      <StaticImage
        src="../../../images/reverse/generic/boss_6.webp"
        alt="Raid Boss"
      />
      <p>
        A returning boss from the Notes of Shuori (1.6) event, the Isle Cryptid
        touches down in Mane’s Bulletin as a signature raid for any character
        that can easily upgrade either their own or other’s cards. Prime that
        Tuning and prepare your upgrades, you’ll be in the way of thunder itself
        this time.
      </p>
      <p>There are a total of 3 types of enemies in this fight:</p>
      <ul>
        <li>The Isle Master who appears as the great green stag.</li>
        <li>The Shepherd who rides into battle on a horse.</li>
        <li>The Cupbearer provides its allies with drinks.</li>
      </ul>
      <SectionHeader title="Field Hazards [Endless Mode]" />
      <ul>
        <li>Plant Afflatus characters have increased stats.</li>
        <li>
          Mental characters deal increased damage. If they cast a rank 2/3
          incantation, they gain a layer of [Rousing Morale].
        </li>
        <li>
          While the Isle Master is in the [Shield] status, it takes reduced
          damage. When the [Shield] is broken, the Isle Master takes massively
          increased damage for a single round.
        </li>
      </ul>
      <SectionHeader title="Boss Mechanics [Endless Mode]" />
      <h5>Isle Master</h5>
      <ul>
        <li>
          <strong>Call From Above</strong> - When attacked, gains 1 stack of
          [Thunderous Movement]; if attacked with a rank 2/3 incantation, gain
          an additional 2/4 stacks. At the end of the round, consume all stacks
          of [Thunderous Movement] to trigger any of the following effects:
        </li>
        <ul>
          <li>
            At 8-13 stacks, cast [Sky Cloud] as a follow-up attack. At 13+
            stacks, deal damage to self.
          </li>
          <li>
            → [Sky Cloud]: Deals Mental DMG to 2 enemies. If in the [Shield]
            status, this attack gains additional Penetration Rate.
          </li>
          <ul>
            <li>
              The idea here is that you overload the boss by continuously
              attacking it with rank 2/3 while ensuring that its [Shield] never
              stays on for long.
            </li>
          </ul>
        </ul>
        <li>
          <strong>Roar of Thunder</strong> - If in the [Shield] state, gain
          Penetration Rate. When attacking, if under the effect of 2+ [Stats
          Down] or [Neg Status], DMG Dealt decreased.
        </li>
        <ul>
          <li>
            Further encouraging players to remove the shield as quickly as
            possible and inciting you to bring a debuffer to help mitigate
            damage further.
          </li>
        </ul>
        <li>
          <strong>Control Immunity</strong> - As is standard with the main body
          of a boss, the Isle Master is immune to [Control] effects.
        </li>
        <li>
          Like the other Raid Bosses, when maximum [Eureka] is reached, the boss
          enters a [Shield] phase for 2 rounds before casting its Ultimate. If
          the [Shield] is broken, they are knocked out for a round and lose all
          their [Eureka].
        </li>
        <li>
          [Attack] Card - Deals damage to 1 target and grants Isle Master a
          [Shield] for 2 rounds.
        </li>
        <li>
          [Attack] Card - Deals damage to 2 targets. Gains extra Penetration
          Rate if the Isle Master has a [Shield].
        </li>
      </ul>
      <h5>Shepherd</h5>
      <ul>
        <li>
          <strong>Empathic Sorcery</strong> - At the end of the round, if this
          unit hasn’t taken any damage for the round, enters [Taunt] for 1
          round. The [Taunt] is dispelled if attacked by a rank 2/3 incantation.
        </li>
        <ul>
          <li>
            This promotes the use of Mass/Splash attackers or those with access
            to damage over time effects.
          </li>
        </ul>
        <li>
          <strong>New Spring</strong> - When taking lethal damage, grants 3
          stacks of [Thunderous Movement] to the Isle Master. Then, this unit
          recovers some HP and enters the [Disarm] status for 2 rounds.
        </li>
        <ul>
          <li>
            This invites you to apply as many Mass attacks as possible, as the
            idea is to get the Isle Master to +13 stacks of [Thunderous
            Movement] as soon as possible.
          </li>
        </ul>
      </ul>
      <h5>Cupbearer</h5>
      <ul>
        <li>
          {' '}
          <strong>New Spring</strong>- When taking lethal damage, grants 3
          stacks of [Thunderous Movement] to the Isle Master. Then, this unit
          recovers some HP and enters the [Disarm] status for 2 rounds.
        </li>
      </ul>
      <SectionHeader title="Teambuilding" />
      <p>
        Mental DMG carries shine in this fight, especially if Plant Afflatus.
        Star and Beast can also work if they upgrade their cards consistently.
      </p>
      <p>General carry examples:</p>
      <ul>
        <li>
          <strong>Marcus</strong>: Explained in detail below
        </li>
        <li>
          <strong>Satsuki</strong>: High ST DMG potential with Penetration Rate
          and good synergy with the other Plant options
        </li>
        <li>
          <strong>37</strong>: Gets a good boost on her performance if paired
          with Isolde.
        </li>
        <li>
          <strong>Charlie</strong>: High burst DMG, and has Dispel on her
          ultimate.
        </li>
      </ul>
      <p>
        Characters with Dispel are very useful in this fight, but unfortunately
        most of them are of Mineral Afflatus and will get hurt a lot by the
        boss. For this reason, if you're struggling with enemy buffs and have
        him built, X can be a decent option to bring along that can fit in any
        team.
      </p>
      <div className="team-box">
        <h5 className="beast">Nerding Out</h5>
        <div className="team raid">
          <div className="role left">
            <div className="characters">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="marcus"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt with-text">
              <p>&nbsp;</p>
            </div>
            <h6 className="dps">Carry</h6>
          </div>
          <div className="role">
            <div className="characters small">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="an-an-lee"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt with-text">
              <p>&nbsp;</p>
            </div>
            <h6 className="sub-carry">Carry / Support</h6>
          </div>
          <div className="role left">
            <div className="characters small">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="6"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="druvis-iii"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <h6 className="support">Support/Healer</h6>
          </div>
          <div className="role">
            <div className="characters">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="tooth-fairy"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="yenisei"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <h6 className="sustain">Healer</h6>
          </div>
        </div>
        <div className="info raid">
          <h6>TEAM EXPLANATION</h6>
          <p>
            A team surrounding the new Plant Carry, Marcus, that is focused on
            upgrading cards as often as possible in order to quickly generate
            stacks of [Thunderous Movement] and keep the boss occupied.
          </p>
          <ul>
            <li>
              Marcus is the core of the team here, able to quickly upgrade her
              own cards through the consumption of Eureka which allows for
              constant [Thunderous Movement] generation. The team is more or
              less specific, however, as An-An Lee and 6 fulfill the [Perusal]
              requirements or Marcus stops feeling all that powerful.
            </li>
            <li>
              An-An Lee is here of course, being the only other 6-star currently
              centered around upgrading her own cards which can be used for
              Tuning in order to shuffle more Marcus cards into the hand or
              throw out big buffs.
            </li>
            <li>
              6 is here for multiple reasons: a defensive anchor, since Marcus
              loses [Perusal] if her team stops having 3 Plant/Intellect/Spirit
              characters in it. Secondly, because he gets access to a somewhat
              consistent [Incantation Empowerment I] through his Eureka. And
              finally, because he has very consistent semi-Mass debuffs that
              help trigger -DMG Dealt effects on the boss.
            </li>
            <li>
              There is an even split between Tooth Fairy and Yenisei here, and I
              would argue Yenisei has the potential to overtake Tooth Fairy in
              this fight. Why? Because Marcus’ Crit Tech is abysmal, she doesn’t
              see much use from Tooth Fairy’s debuffs.
            </li>
          </ul>
          <p>For other options:</p>
          <ul>
            <li>
              <strong>Yenisei</strong> - As a powerful defensive healer, the
              further you go into Endless mode, the more valuable she becomes.
              In addition, her access to upgrading her own cards through her
              [Flow] buff greatly aided in this fight specifically.
            </li>
            <li>
              <strong>Druvis III</strong> - Not as useful as characters with
              upgrade mechanics, Druvis can be used to support with her [Circle
              of Life] buff and great Mass damage.
            </li>
          </ul>
        </div>
      </div>
      <div className="team-box">
        <h5 className="beast">Burning Up</h5>
        <div className="team raid">
          <div className="role left">
            <div className="characters">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="spathodea"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="jiu-niangzi"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <h6 className="dps">Carry</h6>
          </div>
          <div className="role">
            <div className="characters small">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="isolde"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt with-text">
              <p>&nbsp;</p>
            </div>
            <h6 className="sub-carry">Carry/Support</h6>
          </div>
          <div className="role left">
            <div className="characters small">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="getian"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="an-an-lee"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <h6 className="support">Support/Healer</h6>
          </div>
          <div className="role">
            <div className="characters">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="tooth-fairy"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <div className="characters alt">
              <Card className="avatar-card">
                <Reverse1999Character
                  slug="yenisei"
                  mode="icon"
                  showTags
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
            <h6 className="sustain">Healer</h6>
          </div>
        </div>
        <div className="info raid">
          <h6>TEAM EXPLANATION</h6>
          <p>
            This team is focused around Spathodea and the [Burn] debuff,
            preferring to forgo the immediate focus on rank 2/3 incantations and
            honing in on massive burst damage instead.
          </p>
          <ul>
            <li>
              Spathodea performs very well with her extremely high single-target
              damage. Not only is her damage consistent with Isolde’s presence
              on the field, she has a niche addition in generating rank 2/3
              cards with rank 2/3 “Ignition Point” casts.
            </li>
            <li>
              Isolde is the Support of all time. In this case, her constant
              application of [Burn] in a semi-Mass way allows her to satisfy
              -DMG Dealt conditions on the enemies and her ramping amount of
              FUAs especially with Spathodea there helps deal with the Shepherd
              and Cupbearer ads.
            </li>
            <li>
              Getian’s focus on Reality DMG allows him to pair well with the
              powerful Physical attackers we have at our disposal and the newly
              introduced Isolde, providing -Reality DEF on a consistent basis
              and also an Array that provides a substantial DMG Dealt bonus to
              specifically Reality DMG teammates. The extra Moxie he gains at
              the start of combat does limit him somewhat in his team building
              options but that is easier to resolve in Raids than elsewhere.
            </li>
            <li>
              There is an even split between Tooth Fairy and Yenisei here, and I
              would argue Yenisei has the potential to overtake Tooth Fairy in
              this fight. It mostly comes down to how squishy your Spathodea and
              Isolde are, and what Portray your Spathodea is carrying. With more
              Portrays comes less need for Crit RES debuffing.
            </li>
          </ul>
          <p>For other options:</p>
          <ul>
            <li>
              <strong>Jiu Niangzi</strong> - Jiu has Afflatus Disadvantage in
              this fight, and it hits really hard, so use her with caution.
            </li>
            <li>
              <strong>Yenisei</strong> - As a powerful defensive healer, the
              further you go into Endless mode, the more valuable she becomes.
              In addition, her access to upgrading her own cards through her
              [Flow] buff greatly aided in this fight specifically.
            </li>
          </ul>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default RevShouldAvgust;

export const Head: React.FC = () => (
  <Seo
    title="Isle Cryptid | Reverse: 1999 | Prydwen Institute"
    description="Everything you need to know about the Isle Cryptid Raid!"
    game="reverse"
  />
);
